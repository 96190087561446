import { getAssets } from "@unisearch/helpers";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import styles from "./features.module.scss";

const Features = () => {
  return (
    <div className={styles.features_wrapper}>
      <ul>
        <li>
          <Link href="/explore-university">
            <Image
              width={45}
              height={44}
              src={getAssets("assets/images/home/explore-university.webp")}
              alt="Explore Universities"
              title="Explore Universities"
            />
            <span className={styles.content}>Explore Universities</span>
          </Link>
        </li>

        <li>
          <Link href="/counsellors">
            <Image
              width={45}
              height={44}
              src={getAssets("assets/images/home/free-counselling.webp")}
              alt="Free Counselling"
              title="Free Counselling"
            />
            <span className={styles.content}>Free Counselling</span>
          </Link>
        </li>
        <li>
          <Link href="/documents">
            <Image
              width={45}
              height={44}
              src={getAssets("assets/images/home/applications.webp")}
              alt="Applications"
              title="Applications"
            />
            <span className={styles.content}>Applications</span>
          </Link>
        </li>
        <li>
          <Link href="/exams">
            <Image
              width={45}
              height={44}
              src={getAssets("assets/images/home/admission-tests.webp")}
              alt="Admissions Tests"
              title="Admissions Tests"
            />
            <span className={styles.content}>Admissions Tests</span>
          </Link>
        </li>
        <li className="relative">
          <span className={`uni-tooltip ${styles.msg}`}>Coming Soon</span>
          <Link href="#">
            <Image
              width={45}
              height={44}
              src={getAssets("assets/images/home/discussion-forums.webp")}
              alt="Discussion Forums"
              title="Discussion Forums"
            />
            <span className={styles.content}>Discussion Forums</span>
          </Link>
        </li>
        <li className="relative">
          <Link href="/vfair">
            <Image
              width={45}
              height={44}
              src={getAssets("assets/images/home/events.webp")}
              alt="Events"
              title="Events"
            />
            <span className={styles.content}>Events</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Features;
