import { Grid } from "@mui/material";
import dynamic from "next/dynamic";
import Image from "next/legacy/image";
import { Fragment } from "react";
import Skeleton from "@unisearch/common/skeleton";
import Features from "../components/features";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styles from "./banner.module.scss";

const Slider = dynamic(() => import("react-slick"), {
  ssr: false,
  loading: () => <Skeleton height={450} />,
});
const CommonSearch = dynamic(
  () => import("@modules/study/common/advance_search/common"),
  {
    loading: () => <Skeleton className={styles.search_course_skeleton} />,
  }
);

import hero_one from "../../../../public/assets/images/home/Hero-Img-1.webp";
import hero_two from "../../../../public/assets/images/home/Hero-Img-2.webp";
import hero_three from "../../../../public/assets/images/home/Hero-Img-3.webp";

export const HomeBanner = () => {
  const settings = {
    dots: false,
    arrows: false,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <Fragment>
      <section className={styles.section_banner_two}>
        <div className="container">
          <Grid container spacing={3} className={styles.banner_two}>
            <Grid
              item
              sm={12}
              md={6}
              sx={{ zIndex: "2", alignSelf: "flex-start" }}
            >
              <div className={styles.banner_content_wrapper}>
                <div className={`${styles.title_wrapper}`}>
                  <h1 className={`${styles.title} fw400`}>
                    <span>The Comprehensive Toolkit for Every Stage of </span>
                    <span className="mark">Study Abroad</span>
                  </h1>
                  <p className={`${styles.sub_title} sub_title`}>
                    Bringing international student experience and intelligent
                    technology together to make study abroad solutions simpler
                    than ever
                  </p>
                </div>
                <div className={styles.advanced_search_wrapper}>
                  <CommonSearch />
                </div>
              </div>
            </Grid>
            <Grid item sm={12} md={6} sx={{ zIndex: "1" }}>
              <Slider {...settings}>
                <div className={styles.image_wrapper}>
                  <Image
                    layout="responsive"
                    src={hero_one}
                    alt="Unisearch study international"
                    title="Unisearch study international"
                    placeholder="blur"
                    blurDataURL="/assets/images/image_placeholder_big.webp"
                    priority
                  />
                </div>
                <div className={styles.image_wrapper}>
                  <Image
                    layout="responsive"
                    src={hero_two}
                    alt="Unisearch study international"
                    title="Unisearch study international"
                    placeholder="blur"
                    blurDataURL="/assets/images/image_placeholder_big.webp"
                  />
                </div>
                <div className={styles.image_wrapper}>
                  <Image
                    layout="responsive"
                    src={hero_three}
                    alt="Unisearch study international"
                    title="Unisearch study international"
                    placeholder="blur"
                    blurDataURL="/assets/images/image_placeholder_big.webp"
                  />
                </div>
              </Slider>
            </Grid>
          </Grid>
        </div>
      </section>
      <section>
        <Features />
      </section>
    </Fragment>
  );
};

export default HomeBanner;
